.border-none:before {
  display: none;
}
.border-none:after {
  display: none;
}
.flex-fix {
  -ms-flex: 1 1 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  -webkit-flex-basis: 0px;
  flex-basis: 0px;
  width: 1%;
}
.hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.allcover {
  position: absolute;
  top: 0;
  right: 0;
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.ct {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.cl {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.clear-fix {
  *zoom: 1;
}
.clear-fix:before,
.clear-fix:after {
  content: "";
  display: table;
  clear: both;
  overflow: hidden;
}
.pwd-page {
  padding: 2rem 0.34rem 0 0.36rem;
  font-size: 0.36rem;
  color: #333;
}
.pwd-page .pwd-box {
  margin-top: 0.6rem;
  width: 100%;
  height: 1rem;
}
.pwd-page .pwd-box input {
  width: 100%;
  height: 1rem;
  position: absolute;
  opacity: 0;
  left: 0;
  text-indent: -999em;
  /*文本向左缩进*/
  margin-left: -100%;
  /*输入框光标起始点向左左移*/
  width: 200%;
  /*输入框增大一倍*/
}
.pwd-page .pwd-box .item {
  flex-basis: 16.6%;
  text-align: center;
  line-height: 1rem;
  background: #fff;
  border: 0.01rem solid #e1e1e1;
}
.pwd-page .pwd-box .item .point {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #333;
}
.pwd-page .pwd-box .item:not(:last-of-type) {
  border-right: 0;
}
